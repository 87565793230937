import React, { useState } from 'react';
import './footer.css';
import { NewsLetter } from '../../server/server';

const Footer = () => {
  const [email, emailUpdated]=useState("")
  const RegisterLetter=async ()=>{
    await NewsLetter(email)
    emailUpdated("")
    alert("Thank you for signing up for Nodamec NewsLetter")
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="logo">
          <span><img src={require('../../images/Nodamec Logo 2.jpeg')} alt="Nodamec Logo" /> {/* Replace with actual logo URL */}
         </span>
        </div>
        <div className="help">
        <a href="/"><i className="fa fa-phone"></i></a>
          <span >Need help? Call us</span>
          <a href="tel:+2348030451579">+234 803 0451 579</a>
        </div>
      </div>
      <div className="footer-container">
        <div className="footer-section contact">
          <h4>Contact Us</h4>
          <p>Plot 101 East-West Road Rumuodara, P.O.Box 14247, Port-Harcourt, Rivers State.</p>
          <p>Hours: 8:00 - 17:00, Mon - Sat</p>
          <p>Email:  info@nodamectravels.com</p>
          <div className="social-icons">
            <a href="www.facebook.com"><i className="fab fa-facebook-f"></i></a>
            <a href="www.twitter.com"><i className="fab fa-twitter"></i></a>
            <a href="www.instagram.com"><i className="fab fa-instagram"></i></a>
            <a href="www.youtube.com"><i className="fab fa-youtube"></i></a>
          </div>
        </div>

        <div className="footer-section company">
          <h4>Company</h4>
          <ul>
            <li><a href="/">About Us</a></li>
            <li><a href="/"> Blog</a></li>
            <li><a href="/">Contact Us</a></li>
            <li><a href="/">Partners</a></li>
          </ul>
        </div>
        <div className="footer-section services">
          <h4>Services</h4>
          <ul>
            <li><a href="/">Tour Booking</a></li>
            <li><a href="/">Flight Ticketing</a></li>
            <li><a href="/">Hotel Booking</a></li>
            <li><a href="/">Visa Assistance</a></li>
            <li><a href="/">Airport Transfer</a></li>
          </ul>
        </div>
        <div className="footer-section legal">
        <div className="footer-column">
                    <h4>Get Our Promo/Offers</h4>
                    <p>We value your feedback and are here to address any concerns you may have. Please let us know how we can improve our services.</p>
                    <form>
                        <input id='textarea' placeholder="Email Address" value={email} onChange={(e)=>emailUpdated(e.target.value)} style={{height:'30px', marginBottom:'10px'}}/>
                        <button style={{backgroundColor:'white',color:'#22516f',border:"none",padding:'10px 40px',fontWeight:'bold'}} type="submit" onClick={()=>RegisterLetter()}>Submit</button>
                    </form>
                </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Nodamec Travels Limited. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
